<template>
  <service-menu current="support"/>
  <div class="service-top">
    <div class="content">
      <h1>仕入れ代行サービス</h1>
      <p>
        業界最安値で、商品選定から検品までフルでサポート
      </p>
    </div>
  </div>
  <div class="pain">
    <p class="title">海外仕入れで困っていませんか？</p>
    <div class="items">
      <div class="left pc">
        <img src="@/assets/speech-1.svg"/>
        <img src="@/assets/speech-4.svg"/>
      </div>
      <div class="center">
        <img src="@/assets/headache.png"/>
      </div>
      <div class="right pc">
        <img src="@/assets/speech-2.svg"/>
        <img src="@/assets/speech-3.svg"/>
      </div>
      <div class="text sp">
        <li>“やりとりが面倒くさい”</li>
        <li>“中国語がわからない”</li>
        <li>“商品の品質には不安”</li>
        <li>“人民元の支払いができない”</li>
      </div>
    </div>
    <div class="solution">
      <p>SellerPortの仕入れ代行サービスで全て解決</p>
      <div class="merits">
        <div class="merit">
          <span>商品探しお手伝い</span>
          <span>※会員プランのみ</span>
        </div>
        <div class="merit">
          <span>サンプル商品確認</span>
          <span>してから発注可能</span>
        </div>
        <div class="merit">
          <span>プロのバイヤーが</span>
          <span>担当するから安心</span>
        </div>
        <div class="merit">
          <span>日本円の請求書払い</span>
          <span>支払いが楽チン</span>
        </div>
        <div class="merit">
          <span>現地買い付けツアー</span>
          <span>イベント多数</span>
        </div>
      </div>
    </div>
  </div>
  <plan/>
  <service-flows  background="white" />
  <div class="customer-voice">
    <h1>お客様の声</h1>
    <div class="customer-voice-row">
      <customer-voice
        align="left"
        title="スタッフの方がとても親切でした"
        description="海外輸入の初心者です。<br>
輸入の仕組みを丁寧に説明してくれたり、円安でコストが高くなると相談したら、
中国語も全く分からない状態の私達に1688での商品探しの方法も教えてくれました。<br>
ゼロベースからスタートでも、無事に商品が届き、今では安定して販売できています。<br>
本当にSellerPortのおかげです。とてもいいサービスでした。"
        profile="user1.png"
        />
      <customer-voice
        align="left"
        title="信頼できるパートナーです"
        description="<br>ずっと仕入れサービスは利用していたのですが、<br>もっと丁寧かつ積極的に動いてくれるサービスはないかと調べていたところにSellerPortに出会いました。
<br><br>試してみたら作業も安心してお任せできるし、商品選定からOEMまで積極的に提案してくれて、とても助かっています。
"
        profile="user2.png"
        />
    </div>
  </div>
  <div class="faqs">
    <h1>よくあるご質問</h1>
    <faq
      v-for="(faq, index) in faqs"
      :key="index"
      :question="faq.question"
      :answer="faq.answer"
      />
  </div>
</template>

<script>
import Faq from '@/components/atoms/Faq.vue'
import Plan from '@/components/molecules/Plan.vue'
import ServiceFlows from '@/components/molecules/ServiceFlows.vue'
import CustomerVoice from '../components/molecules/CustomerVoice.vue'
import ServiceMenu from '../components/molecules/ServiceMenu.vue'

export default {
  name: 'Service',
  components: {
    Faq, Plan, ServiceFlows, CustomerVoice, ServiceMenu,
  },
  data() {
    return {
      faqs: [
        {
          question: 'お見積もりは無料でしょうか？',
          answer: '無料です、ぜひお問い合わせください。',
        },
        {
          question: '中国語は全く分かりませんが商品は希望のものが購入できるのでしょうか?',
          answer: '商品についてご不明な点などは担当のスタッフまでご相談ください。弊社よりショップに確認させて頂きます。またご注文シートでは行き違いの無いよう、可能な限り商品掲載URL・商品画像・サイト上での表現での種類・色等の詳細をご記載ください。',
        },
        {
          question: '価格交渉はしていますか？',
          answer: '仕入の際は、必ず交渉しております。また、値段交渉は購入時に販売先に掛け合うことは可能です。しかし、販売元によって、それぞれ注文量の条件等が異なりますので、その交渉が成功するかはお約束いたしかねます。弊社といたしましては、お客様のコスト削減につながるように努力いたします。',
        },
        {
          question: '購入後のキャンセルは可能ですか？',
          answer: 'ご入金前の場合はキャンセル可能です。 ※ご入金後の途中キャンセルはできませんので、ご了承ください。',
        },
        {
          question: '返品や交換は可能ですか？',
          answer: '返品や交換は、すぐに対応できればセラーに交渉することはできますが、国際発送の場合は日数が経過しているので基本的には返品や交換が難しいです。 返品の場合は、国内送料が別途かかる場合もございます。',
        },
      ]
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.service-top {
  background: url('../assets/support-top.png');
  height: 350px;
  .content {
    background: rgba(51,51,51,0.78);
    height: 100%;
    padding-left: 8%;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h1 {
      border-bottom: 1px solid white;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}
.pain {
  p {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }
  .items {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    align-items: center;
    .center {
      display: inline-flex;
      margin: 0 50px;
      z-index: 2;
      img {
        width: 200px;
        height: 200px;
      }
    }
    .left, .right {
      display: inline-flex;
      flex-direction: column;
      width: 40%;
    }
    .left {
      align-items: flex-end;
      img:nth-child(1) {
        width: 300px;
      }
      img:nth-child(2) {
        width: 350px;
        margin-top: 50px;
      }
    }
    .right {
      align-items: flex-start;
      img:nth-child(1) {
        width: 300px;
      }
      img:nth-child(2) {
        margin-top: 40px;
        width: 330px;
      }
    }
  }
  .solution {
    background: #F2B504;
    color: white;
    text-align: center;
    padding: 50px 0 80px 0;
    font-size: 22px;
    font-weight: bold;
    p {
      margin: auto;
    }
    &::before {
      content: "";
      border-top: 30px solid transparent;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #F2B504;
      position: relative;
      top: -100px;
    }
    .merits {
      font-size: 14px;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      .merit {
        padding: 0 20px;
      }
      .merit:not(:last-child) {
        border-right: 1px solid white;
      }
    }
  }
}
.plan {
  background: #F7F7F7;
  padding-bottom: 80px;
  h2 {
    margin: 0;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  table {
    width: 70%;
    margin: auto;
    padding: 3px;
    td {
      border: solid 5px #F7F7F7;
      padding: 0px;
      text-align: center;
    }
    td {
      padding: 10px;
      background: white;
    }
    .category {
      background: #606060;
      color: white;
      text-align: center;
      font-weight: bold;
    }
    .transparent {
      background: transparent;
    }
  }
}
.customer-voice {
  background: #F7F7F7;
  padding-top: 50px;
  padding-bottom: 100px;
  h1 {
    text-align: center;
  }
  .customer-voice-row {
    display: flex;    
    justify-content: space-around;
  }
}
.faqs {
  h1 {
    text-align: center;
  }
}

@media (min-width: 751px) {
  .pain {
    .solution {
      .merits {
        .merit {
          display: block;
        }
       }
    }
  }
  .customer-voice {
    h1 {
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 750px) {
  .service-top {
    background: url('../assets/support-top-sp.png');
    background-size: cover;
  }
  .pain {
    p {
      font-size: 22px;
    }
    .items {
      flex-direction: column;
      .center {
        img {
          width: 150px;
          height: 150px;
        }
      }
      .text {
        background: #F7F7F7;
        border-radius: 10px;
        width: 80%;
        padding-top: 100px;
        padding-bottom: 20px;
        z-index: 1;
        margin-top: -70px;
        font-weight: bold;
        li {
          list-style-type: none;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
    .solution {
      p {
        width: 65%;
      }
      &::after {
        top: -370px;
      }
      .merits {
        flex-direction: column;
        .merit {
          text-align: left;
          text-indent: 30px;
          margin-bottom: 13px;
          &::before {
            content: '';
            margin-left: 10px;
            margin-right: 10px;
            border-left: 8px solid white;
          }
        }
      }
    }
  }
  .customer-voice {
    .customer-voice-row {
      flex-direction: column;
      .voice:not(:nth-child(1)) {
        margin-top: 30px;
      }
    }
  }
}
</style>