<template>
  <div class="faq">
    <div
      @click="onClick"
      class="question">
      <span class="before">
        <img src="@/assets/icon-q.svg"/>
      </span>
      <div class="title">{{question}}</div>
      <div class="icon" :class="{down: spreaded}"></div>
    </div>
    <div
      v-if="spreaded"
      class="answer">{{answer}}</div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  props: {
    question: String,
    answer: String,
  },
  data() {
    return {
      spreaded: false,
    }
  },
  methods: {
    onClick() {
      this.spreaded = !this.spreaded
    }
  }
}
</script>

<style lang="scss">
@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.faqs {
  padding: 50px 0 80px 0;
}

.faq {
  margin: auto;
  padding-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #999999;
  .question {
    display: flex;
    cursor: pointer;
    align-items: center;
    .before {
      margin-right: 10px;
      img {
        width: 20px;
      }
    }
    .title {
      width: 100%;
      padding-right: 10px;
    }
    .icon {
      border: solid #999999;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);      
    }
    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-bottom: 10px;
    }
  }

  .answer {
    padding-top: 10px;
    padding: 10px 5px;
    color: rgb(78, 78, 78);
    animation: fadein 0.5s linear 0s 1;
  }
}

@media (min-width: 751px) {
  .faq {
    max-width: 70%;
    letter-spacing: 1.5px;
  }
}

@media screen and (max-width: 750px) {
  .faq {
    width: 85%;
    // .icon {
    //   flex: 1;
    // }
    .title {
      flex: 15;
    }
  }
}

</style>
