<template>
  <div class="service-list">
    <a :class="{active: isSupport}" href="/service">仕入れ代行<span class="pc">サービス</span></a>
    <divider/>
    <a :class="{active: isInspection}" href="/service/inspection">検品<span class="pc">サービス</span></a>
    <divider/>    
    <a :class="{active: isLogistics}" href="/service/logistics">国際郵送<span class="pc">サービス</span></a>
    <divider/>    
    <a :class="{active: isOem}" href="/service/oem">OEM<span class="pc">サービス</span></a>
  </div>
</template>

<script>

export default {
  name: 'ServiceMenu',
  components: {
  },
  props: {
    current: String,
  },
  data() {
    return {  
      menus: [
        {path: '/service', name: '仕入れ代行サービス'},
        {path: '/service/inspection', name: '検品サービス'},
        {path: '/service/logistics', name: '国際郵送サービス'},
        {path: '/service/oem', name: 'OEMサービス'},                        
      ]
    }
  },
  computed: {
    isSupport() {
      return this.current === 'support'
    },
    isInspection() {
      return this.current === 'inspection'
    },
    isLogistics() {
      console.log(this.current)
      return this.current === 'logistics'
    },
    isOem() {
      return this.current === 'oem'
    }        
  }
}
</script>

<style lang="scss" scoped>
.service-list {
  display: flex;
  padding: 40px;
  justify-content: space-evenly;
  margin: auto;
  divider {
    border-right: 1px solid #C4C4C4;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .active {
    font-weight: bold;
    color: #F2B504;
  }
}

@media (min-width: 751px) {
  .service-list {
    width: 70%;
  }  
}
</style>