<template>
  <div class="plans">
    <h1>料金プラン</h1>
    <div class="plan-container fade-in-elements">
      <div class="plan fade-in-element">
        <div class="notice">人気No.1</div>
        <div class="plan-info">
          <h2>会員プラン</h2>
          <p class="primary-color">
            <span class="row">
              月額利用料
              <span class="font-30">10,000</span>
              円(税込)/月 
            </span>
            <span class="row font-30">＋</span>
            <span class="row">
              代行手数料
              <span class="font-30">5</span>％
            </span>
          </p>
          <p>
            代行手数料<span class="font-30 horizon-space">25%</span>OFF
          </p>
          <p>
            検品手数料<span class="font-30 horizon-space">20%</span>OFF
          </p>
        </div>
      </div>
      <div class="plan fade-in-element">
        <div class="notice">初心者向け</div>
        <div class="plan-info">
          <h2>一般プラン</h2>
          <p class="primary-color">
            <span class="row">
              月額利用料
              <span class="font-30">0</span>
             円(税込)/月 
            </span>            
            <span class="font-30 row">＋</span>
            <span class="row">
              代行手数料
              <span class="font-30">7</span>％
            </span>
          </p>
          <p class="divder">
          </p>
          <p class="loose">
            月額利用料なし
          </p>          
          <p class="loose">
            最低手数料2,000円(税込)
          </p>
        </div>
      </div>
    </div>
    <div class="statement">※輸送料金(船便、エア便)などお問い合わせください。</div>
  </div>
</template>

<script>
export default {
  name: 'Plan',
}
</script>

<style lang="scss" scoped>
.plans {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #F7F7F7;
  h1 {
    text-align: center;
  }
  .plan-container {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: center;
    align-items: center;
    .plan {
      flex: 1;
      text-align: center;
      padding: 3%;
      .notice {
        background: #F2B504;
        color: white;
        width: 50%;
        margin: auto;
        padding: 10px 5px;
        border-radius: 10px;
        font-weight: bold;
        position: relative;
        margin-bottom: 15px;
        &::after {
          content: '';
          position: absolute;
          border-top: 13px solid #F2B504;
          border-right: 9px solid transparent;
          border-left: 9px solid transparent;
          bottom: -11px;
          left: 45%;
        }      
      }
      .plan-info {
        background: white;
        border-radius: 5px;
        padding: 5px 10px;
        box-shadow: 0px 6px 4px -3px rgba(0,0,0,0.25);
        p {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .primary-color {
        color: #F2B504;
      }
      .font-30 {
        font-size: 30px;
      }
    }
  }

  .statement {
    margin: auto;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
  }

  .horizon-space {
    padding: 0px 5px;
  }
}
@media (min-width: 751px) {
  table {
    width: 80%;
  }
  .plan {
    padding-bottom: 50px;
  }
  .statement {
    width: 80%;
  }
  .loose {
    margin: 26px 0px;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 750px) {
  .plan-container {
    flex-direction: column;
    .plan:nth-child(2) {
      margin-top: 50px;
    }
    .plan {
      width: 85%;
    }
  }
  table {
    width: 90%;
  }
  .statement {
    width: 90%;
  }
  .row {
    display: block;
  }
}
</style>
