<template>
  <div class="voice" :class="[ isAlignLeft ? 'voice-left': 'voice-right' ]">
    <div class="box">
      <div class="profile">
        <div class="img">
          <img :src="require(`@/assets/${profile}`)">
        </div>
      </div>
      <div class="content">
        <div class="title">
          <span>{{title}}</span>
          <span class="stars">
            <img src="@/assets/icon-star.svg"/>
            <img src="@/assets/icon-star.svg"/>
            <img src="@/assets/icon-star.svg"/>
            <img src="@/assets/icon-star.svg"/>
            <img src="@/assets/icon-star.svg"/>
          </span>   
        </div>
        <div class="description" v-html="description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Plan',
  props: {
    align: String,
    title: String,
    description: String,
    profile: String,
  },
  computed: {
    isAlignLeft() {
      return this.background === 'white'
    }
  },
}
</script>

<style lang="scss" scoped>
.voice {
  display: flex;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  background: white;
  border-radius: 10px;
  position: relative;
  .box {
    position: relative;
    z-index: 5;
    background: white;
    border-radius: 10px;
    display: flex;
  }
  .profile {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
    .img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid #F2B504;
      img {
        width: 100%;
      }
    }
  }
  .content {
    padding: 10px;
    .title {
      font-weight: bold;
    }
  }
  .description {
    flex: 3;
    font-size: 14px;
  }
}

@media (min-width: 751px) {
  .voice {
    width: 38%;
    .box {
      padding: 15px;
      .profile {
        margin: 10px;
      }
      .title {
        img {
          width: 16px;
          margin: 0 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .voice {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    border: 1px solid #F2B504;
    .box {
      flex-direction: column;
      align-items: center;
      .content {
        padding: 15px;
        .title {
          margin-bottom: 10px;
          text-align: center;
          img {
            width: 16px;
            margin: 0 1px;
          }
        }
      }
    }
  }
}
</style>